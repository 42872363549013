import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/providers/language.service';
import { AccountService } from 'src/app/providers/account.service';
import { KeycloakService } from 'keycloak-angular';
import { AccountAvatarService } from 'src/app/providers/avatar.service';
import { Router } from '@angular/router';
import { Lang } from 'src/app/interface/language';

@Component({
    selector: 'app-vertical-header',
    templateUrl: './vertical-header.component.html',
    styleUrls: [],
})
export class VerticalAppHeaderComponent {
    public config: PerfectScrollbarConfigInterface = {};

    public selectedLanguage: Lang = {
        language: 'Tiếng Việt',
        code: 'vn',
        type: 'VN',
        icon: 'vn',
    };

    public languages: Lang[] = [
        {
            language: 'English',
            code: 'en',
            type: 'US',
            icon: 'us',
        },
        {
            language: 'Tiếng Việt',
            code: 'vn',
            type: 'VN',
            icon: 'vn',
        },
    ];

    avatarLink: string = '';

    constructor(
        private translate: TranslateService,
        private languageService: LanguageService,
        private keycloakService: KeycloakService,
        private accountAvatarService: AccountAvatarService,
        private router: Router
    ) {
        translate.setDefaultLang('vn');
        // this.changeLanguage("vn");

        if (this.accountAvatarService.AvatarLink == '') this.avatarLink = './assets/images/users/default.png';
        else this.avatarLink = this.accountAvatarService.AvatarLink;

        this.accountAvatarService.AvatarLinkSub.subscribe((value: string) => {
            if (value == '') this.avatarLink = './assets/images/users/default.png';
            else this.avatarLink = value;
        });
    }

    async ngAfterViewInit() {
        await this.accountAvatarService.updateAvatarLink();
    }

    changeLanguage(lang: Lang): void {
        this.translate.use(lang.code);
        this.selectedLanguage = lang;
        this.languageService.language = lang.code;
    }

    logout() {
        this.keycloakService.logout();
    }

    accountInfo() {
        this.router.navigateByUrl('/account/editaccount');
    }
}
