import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class FilesDownloadedService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async getAllByUID() {
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Readv1 + '/files-downloaded/get-all-by-uid',
                    {},
                    {
                        headers: { apikey: this.accountService.getAPIKey() },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async markAsReadByUID(filename: string) {
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/files-downloaded/mark-as-read-by-uid',
                {
                    filename: filename,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }
}
