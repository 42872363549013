import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class OrgService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetchNameByCode(code: string) {
        let final_metadata: any = this.accountService.getMetadata();

        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.VnuisAPI_Readv1 + '/org-data/get-by-code', {
                    apikey: this.accountService.getAPIKey(),
                    org_code: code,
                    metadata: final_metadata,
                })
                .subscribe((data: any) => {
                    if (data != 'FAIL' && Array.isArray(data) && data.length > 0) {
                        resolve(data[0].org_code_text);
                        return;
                    }
                    resolve(code);
                });
        });
    }

    async fetchNameFullByCodes(codes: any[]) {
        let final_metadata: any = this.accountService.getMetadata();

        return await new Promise<any[]>((resolve, reject) => {
            this.httpClient
                .post<any[]>(this.hostnameService.VnuisAPI_Readv1 + '/org-data/get-org-name-full-by-codes', {
                    apikey: this.accountService.getAPIKey(),
                    codes: codes,
                    metadata: final_metadata,
                })
                .subscribe((data: any) => {
                    resolve(data);
                });
        });
    }

    async fetchOrgByCode(code: string) {
        let final_metadata: any = this.accountService.getMetadata();

        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.VnuisAPI_Readv1 + '/org-data/get-by-code', {
                    apikey: this.accountService.getAPIKey(),
                    org_code: code,
                    metadata: final_metadata,
                })
                .subscribe((data: any) => {
                    resolve(data);
                });
        });
    }
}
