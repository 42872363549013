import { Injectable } from '@angular/core';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string | string[];
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

export const DASHBOARD_MENUITEMS = {
    state: 'dashboards',
    name: 'menuitems.dashboard',
    type: 'link',
    icon: 'av_timer',
};

export const FILES_MENUITEMS = {
    state: ['storage', 'files-downloaded'],
    name: 'menuitems.luu_tru',
    type: 'link',
    icon: 'archive',
};

export const ACCOUNT_MENUITEMS = {
    state: ['account', 'account-info'],
    name: 'menuitems.tai_khoan',
    type: 'link',
    icon: 'manage_accounts',
};

export const BAO_CAO_TONG_HOP_QLDAQT = {
    state: ['synthetic', 'ban-qldaqt'],
    name: 'menuitems.bao_cao_ban_qldaqt',
    type: 'link',
    icon: 'library_books',
};

export const MENUITEMS = [DASHBOARD_MENUITEMS, BAO_CAO_TONG_HOP_QLDAQT, FILES_MENUITEMS, ACCOUNT_MENUITEMS];

@Injectable({ providedIn: 'root' })
export class MenuItems {
    constructor() {}

    getMenuitem(): Menu[] {
        return MENUITEMS;
    }
}

export function updateMenuItemsRole() {}
